import React, { Component } from 'react';
import { browserHistory, Router, Route, IndexRoute  } from 'react-router'
import { Navbar,Nav, MenuItem,NavDropdown, NavItem} from 'react-bootstrap';
import { LinkContainer} from 'react-router-bootstrap'
import Progress from "react-progress-2";
import browserUpdate from 'browser-update';
import HttpsRedirect from 'react-https-redirect';
import {UNIVERSITY_API_URL, getHeader, getUnivID} from './Config';

import moment from 'moment'

//import logo from './logo.svg';
import './App.css';
import auth from './auth'
import $ from 'jquery'

import NotificationSystem from 'react-notification-system';

import Loader from './components/Loader';

import Login from './pages/Login';
import ForgotPassword from './pages/forgot/ForgotPassword';

function lsTest(){
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

if(lsTest() === true){
    // available
}else{
    // unavailable
    alert('Browser anda tidak mendukung Local Storage! Anda tidak akan bisa login');
}


moment.locale('id');
//cache
var localCache = {
    /**
     * timeout for cache in millis
     * @type {number}
     */
    timeout: 60000*10,//10 minutes
    /** 
     * @type {{_: number, data: {}}}
     **/
    data: {},
    remove: function (url) {
        delete localCache.data[url];
    },
    exist: function (url) {
        return !!localCache.data[url] && ((new Date().getTime() - localCache.data[url]._) < localCache.timeout);
    },
    get: function (url) {
        console.log('Getting in cache for url' + url);
        //console.log(localCache.data[url].data);
        return localCache.data[url].data;
    },
    set: function (url, cachedData, callback) {
        localCache.remove(url);
        localCache.data[url] = {
            _: new Date().getTime(),
            data: cachedData
        };
        if ($.isFunction(callback)) callback(cachedData);
    },
    reset: function(){
		for (var member in localCache.data) delete localCache.data[member];		
	}
};

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (options.cache) {
        var success = originalOptions.success || $.noop,
		url = originalOptions.url;
		//remove jQuery cache as we have our own localCache
		options.cache = false;
		options.beforeSend = function () {
			if (localCache.exist(url)) {
				success(localCache.get(url));
				return false;
			}
			return true;
		};
		options.success = function (data, textStatus) {
			localCache.set(url, data, success);
		};
    }
});

var { Header,
      Brand,
      Toggle,
      Collapse
       } = Navbar;

class AppComp extends React.Component {
    state = {
		loggedIn: auth.loggedIn(),
		_notificationSystem:null,
		univLogo:'',
		univName:'', 
		website:'', 
		programName:''
    };

    updateAuth = (loggedIn) => {
      this.setState({
        loggedIn
      })
    };
	componentDidMount() {
		this.setState({_notificationSystem: this.refs.notificationSystem});
		
		let id = getUnivID();		
		//load university logo
		let url = UNIVERSITY_API_URL+'/universities/'+id;
		$.ajax({
			url, 			
			success:function (data) {				
				if(data.status==1){
					let univ = data.message;
					let univLogo = UNIVERSITY_API_URL+"/upload/"+univ.logo.subDirectory+"/"+univ.logo.localfile;					
					let univName = univ.name;
					let website = univ.website;
					
					this.setState({univLogo, univName, website });
				}else{
					window.notify(data.message, 'error');
				}								
			}.bind(this),
			error:function(error){							
				console.log(error);
				if(error.statusText!="abort"){
					window.notify('Request Failed: '+error.responseText, 'error');
				}
			}
		}).done(function(){
			
		});
	}
    componentWillMount() {
      auth.onChange = this.updateAuth
      //auth.login()
      window.notify = this.notify;      
      window.handleAjaxError = this.handleAjaxError;
      window.redirect = this.redirect;
      window.downloadPDF = this.downloadPDF;
    }
    redirect = (path) => {		
		browserHistory.push(path);
		//window.location.href = '/student'+path;
	}
    notify = (text, type) => {
		var prm = {message: text, level: type, position:'tc'};		
		this.state._notificationSystem.addNotification(prm);
	}
	handleAjaxError = (data) => {
		var msg = '';
		console.log(data);
		if (data.statusText === 'abort') {
			return;
		}
		if (data.status === 0) {
			 msg = 'Not connected. Verify your network.';
		}else{
			if(data.hasOwnProperty('error')){
				msg = data.error.message;
			}else if(data.hasOwnProperty('responseText')){
				try{
					var responseText = $.parseJSON(data.responseText);
					if(responseText.error){				
						if(responseText.error.title=='Exception'){
						}							
						msg = responseText.error.message;
					}else{
						msg = data.responseText;
					}
				}catch(err){
					if(data.responseText==="You don't have access" || data.responseText==="Invalid Token"){
						this.setState({loggedIn:false},()=>{
							window.redirect('/logout');
						});						
					}else{
						alert('failed to parse json: ' +data.responseText);
					}
				}
			}else{
				msg = data.statusText;
			}	
		}
		if(msg!=''){
			this.notify(msg,'error');
		}
		
	}
	waitingForFile = (url,notification) => {
		var timerForLoadingResult=  setInterval(checkServerForFile,4000);//call the fnction in every 4 seconds.write ajax in that function.
		var nt = this.state._notificationSystem;
		function checkServerForFile() {       
			$.ajax({
				url, 
				headers:getHeader(),			
				success:function (data) {				
					clearInterval(timerForLoadingResult) ;       // clear timer     				
					var newWindow = window.open();
					newWindow.location = url;
					nt.removeNotification(notification);
				}.bind(this),
				error:function(error){								
					console.log(error);
					if(error.statusText!="abort"){
						//window.notify('Request Failed: '+error.responseText, 'error');
					}
				}
			}).done(function(){				
			});
			         			
        }
	}
	
	downloadPDF = (url) => {
		//request pdf
		var prm = {message: 'Downloading...', level: 'info', position:'br'};		
		var notification = this.state._notificationSystem.addNotification(prm);
		
		var that = this;
		$.ajax({
			url, 
			headers:getHeader(),			
			success:function (data) {				
				if(data.status==1){
					var urlx = UNIVERSITY_API_URL+'/reports/'+data.message;
					that.waitingForFile(urlx,notification);
				}else{
					window.notify(data.message, 'error');
				}								
			}.bind(this),
			error:function(error){							
				console.log(error);
				if(error.statusText!="abort"){
					window.notify('Request Failed: '+error.responseText, 'error');
				}
			}
		}).done(function(){
			
		});
		
		
	}
	
    render() {
		
		return (    
			<div>		 
				<NotificationSystem ref="notificationSystem" />
				
					<div className="not-login">
						<Navbar fluid collapseOnSelect>
							<Navbar.Header>
								<Navbar.Brand>
									<a href={this.state.website}><img src={this.state.univLogo} className="img-responsive" /></a>
									<div className="univNameBox">
										<h3>{this.state.univName}</h3>										
									</div>	
								</Navbar.Brand>
								
								<Navbar.Toggle />
							</Navbar.Header>
							<Navbar.Collapse>				                  
								
							</Navbar.Collapse>	
						</Navbar>	
						<div className="content container-fluid">
							<div className="row">
								<div className="col-md-12">
									{this.props.children}
								</div>							
							</div>					
						</div>
					</div>		
				
				<footer className="footer">
					<div className="container">
						<p className="feedback pull-right"><a href="#" onClick={()=>{
							window.Raven.showReportDialog();
							}}>Send Feedback / Bug Report</a></p>
					</div>	
				</footer>
			</div>
		)
	}
}

class Home extends React.Component {
    render() {
        return (
          <div>
            <h2>HELLO</h2>
            
          </div>
        );
      }
}

class Dashboard extends React.Component {
    render() {
      const token = auth.getToken()

      return (
        <div>
          <h1>Dashboard</h1>
          
        </div>
      )
    }
}

class About extends React.Component {
    render() {
      return <h1>About</h1>
    }
}

class Logout extends React.Component {
    componentDidMount() {
      auth.logout();
      localCache.reset();
      window.redirect('/login');
    }

    render() {
      return <p>You are now logged out. <a href="/">Click here to Login</a></p>
    }
}

function requireAuth(nextState, replace) {
  if (!auth.loggedIn()) {
    replace({
      pathname: '/student/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}


class App extends Component {
	constructor() {
		super();

		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500); 
		browserUpdate({
			required: {
				e:-2,
				i:11,
				f:-3,
				o:-3,
				s:10.1,
				c:"64.0.3282.16817",
				samsung: 7.0,
				vivaldi: 1.2
			},
			insecure:true
		});
	}

	render() {
		const { loading } = this.state;

		if(loading) {
			return (<Loader />); // render null when app is not ready
		}
		return (
		<HttpsRedirect>
		
		<Router history={browserHistory}>
			<Route path="/" component={AppComp}>
				<IndexRoute component={Login}/>
				<Route path="login" component={Login} />
				<Route path="logout" component={Logout} />
				<Route path="forgot" component={ForgotPassword} />
				
			</Route>
		</Router>
		</HttpsRedirect>
		);
	}
}

export default App;
