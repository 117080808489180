import React from 'react';
import $ from 'jquery'
import ReactDOM from 'react-dom';
import {  withRouter  } from 'react-router'
import { FormGroup, ControlLabel, FormControl, Button} from 'react-bootstrap';
import auth from '../auth'
import {getUnivID, UNIVERSITY_API_URL} from '../Config';

		
const Login = withRouter(class extends React.Component {
    state = {
        error: false
    };
	componentDidMount() {		
		let id = getUnivID();
		ReactDOM.findDOMNode(this.refs.univId).value = id;
		//load university logo
		let url = UNIVERSITY_API_URL+'/universities/'+id;
		$.ajax({
			url, 			
			success:function (data) {				
				if(data.status==1){
					if(data.message.logo){
						$('#logo').attr('src',UNIVERSITY_API_URL+"/upload/"+data.message.logo.subDirectory+"/"+data.message.logo.localfile);
					}
				}else{
					window.notify(data.message, 'error');
				}								
			}.bind(this),
			error:function(error){							
				console.log(error);
				if(error.statusText!="abort"){
					window.notify('Request Failed: '+error.responseText, 'error');
				}
			}
		}).done(function(){
			
		});
    }
    handleSubmit = (event) => {			
        event.preventDefault();
        const username = ReactDOM.findDOMNode(this.refs.username).value
        const pass = ReactDOM.findDOMNode(this.refs.pass).value
        var univId = ReactDOM.findDOMNode(this.refs.univId).value
		var id = getUnivID();
        if(!univId){
			univId = id;
		}
        console.log('usner',username);
        auth.login(username, pass, univId, (loggedIn) => {
            if (!loggedIn){
                //return this.setState({ error: true });
                window.notify('Invalid Login', 'error');
                return false;
            }
            if(localStorage.UserType === 'STUDENT'){				
				window.location = '/student';
			}else if(localStorage.UserType === 'STAFF'){				
				window.location = '/staff';
			}
            
        })
    };

    render() {
        return (
			<div className="login-clean">
				<form onSubmit={this.handleSubmit} className="frmLogin">
															
					<h2 className="text-center">Sistem Informasi Akademik</h2>
					<div className="illustration"><img src="" id="logo" className="img-responsive" /></div>
					<div className="form-group"><input className="form-control"  ref="username" name="username" placeholder="NIM / NIP" /></div>
					<div className="form-group"><input className="form-control" ref="pass" type="password" name="password" placeholder="Password" /></div>
					<div className="form-group"><button className="btn btn-primary btn-block" type="submit">Log In</button></div><a className="pull-right" href="/student/login-with-gmail" className="forgot">Login Mahasiswa dengan Google</a><br /><a className="pull-right" href="#" onClick={()=>{
					window.redirect('/forgot');
					}} className="forgot">Forgot your password?</a>

					<input type="hidden" name="UniversityID" value="" ref="univId" /> 
				   
				</form>		
			</div>	
        )
    }
})
export default Login;
